import React from "react"
import Layout from "../components/layout"

export default ({ location }) => (
  <Layout location={location}>
    <div className="container">
      <h1 className="pageTitle"> Contact The Unity In Community Foundation </h1>
    </div>
    <div className="featuredImage">
      <div className="container">
        <img width="100%" src="/img/directors-half.jpg" alt="" />
      </div>
    </div>
    <div className="block-wysiwyg">
      <div className="container">
        <div className="contentContainer White">
          <div className="content">
            <div>Email the Foundation</div>
            <h3>info@theunityincommunity.org </h3>
            <br />
            <br />
            <div>Call the Foundation</div>
            <h3>(630) 918-5969</h3>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
